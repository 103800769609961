<template>
  <div v-if="peopleToBox?.length" class="absolute inset-0 z-10 pointer-events-none" ref="peopleFacesEl">
    <div
      v-for="person in peopleToBox"
      @contextmenu.prevent
      class="absolute flex-center rounded hover:z-10 transition-all shadow ring-2 opacity-0 hover:opacity-100 hover:scale-105 ring-white/60 hover:ring-white pointer-events-auto"
      :class="{
        'opacity-100': boxMap[person.person_file_id] || showAllBox,
        '!ring-white scale-105': (boxMap[person.person_file_id] && !showAllBox) || (boxMap[person.person_file_id] && showAllBox)
      }"
      :style="{
        top: person.boundingBox.y,
        left: person.boundingBox.x,
        width: person.boundingBox.w,
        height: person.boundingBox.h
      }"
    />
  </div>
</template>

<script setup>
  const props = defineProps({
    file: {
      type: Object,
      required: true
    },
    isActive: Boolean,
    scale: {
      type: Number,
      default: 1
    },
    rotation: {
      type: Number,
      default: 0
    }
  });

  const fileContext = inject('fileContext');
  const filesStore = useFilesStore();

  const peopleFacesEl = ref();
  const peopleToBox = computed(() => props.file.people
    ?.filter(p => p.boundingBox)
    .sort((a, b) => a.bounding_box_left - b.bounding_box_left || a.bounding_box_top - b.bounding_box_top)
  );

  const boxMap = ref({});
  const showAllBox = ref(false);
  const boundingBoxToggleEvent = useEventBus(`core-face-bounding-box-toggle-${props.file.id}`);
  const boundingBoxToggleAllEvent = useEventBus(`core-face-bounding-box-toggle-all-${props.file.id}`);

  boundingBoxToggleEvent.on(({personFileId, forceOff}) => {
    props.file.people?.forEach(person => {
      if (person.person_file_id === personFileId) {
        boxMap.value[personFileId] = forceOff ? false : !boxMap.value[personFileId];
      }
    });
  });

  boundingBoxToggleAllEvent.on(() => {
    showAllBox.value = !showAllBox.value;
  });

  //reset state
  watch(() => props.isActive, (newVal) => {
    if (!newVal) {
      boxMap.value = {};
      showAllBox.value = false;
    }
  }, {immediate: true});

  watch(() => props.file, () => {
    boxMap.value = {};
  });

</script>
